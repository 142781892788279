<template>
    <div>
        <div v-if="spaceInvitations.length && !fetchingSpaceInvitations">
            <div class="d-flex flex-wrap align-center">
                <div v-for="invitationType in availableInvitationTypes" :key="invitationType">
                    <v-checkbox class="mx-4" v-model="invitationsSwitchData[invitationType]">
                        <template v-slot:label>
                            <div>
                                <v-icon class="mr-1" small :color="getMailIconColor(invitationType)">mail</v-icon>
                                <span class="secondary--text caption text-uppercase">{{ invitationAndRequestStatusTypes[invitationType] }}</span>
                            </div>
                        </template>
                    </v-checkbox>
                </div>
                <v-spacer />
                <v-text-field
                    v-model="invitationEmailSearch"
                    autofocus
                    label="Filter"
                    solo
                    flat
                    background-color="grey lighten-4"
                    dense
                    hide-details
                    clearable
                    prepend-inner-icon="mdi-filter"
                    class="mr-2"></v-text-field>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="$store.dispatch('spaceStore/fetchSpaceInvitations', $route.params.sid)" text icon>
                            <v-icon>refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>refresh</span>
                </v-tooltip>
            </div>
            <v-divider class="mb-5"></v-divider>
            <v-data-iterator :items="filteredInvitationsList">
                <template v-slot:default="props">
                    <div v-for="(item, index) in props.items" :key="index">
                        <v-divider inset v-if="index > 0" style="opacity: 0.25; border-width: thin" class="mb-2" />
                        <v-list-item two-line>
                            <v-row align="center" justify="space-between">
                                <v-col cols="1">
                                    <v-icon :color="getMailIconColor(getInvitationStatus(item))" x-large>mail</v-icon>
                                </v-col>
                                <v-col cols="5">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <span class="font-weight-medium secondary--text">{{ item.requestee_email }}</span>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <div class="d-flex flex-column">
                                                <span class="caption mt-1 mb-0" v-if="item.notification_timestamp !== null">
                                                    <span class="font-weight-medium">Sent on</span>
                                                    {{ item.notification_timestamp | dateTimeToHuman }}
                                                </span>
                                                <span
                                                    v-if="
                                                        getInvitationStatus(item) === invitationAndRequestStatusTypes.ACCEPTED &&
                                                        invitationOrRequestHasTimestamp(item)
                                                    "
                                                    class="mt-1 mb-0 caption">
                                                    <span class="font-weight-medium">Accepted on</span>
                                                    {{ item.invitation_responses[0].timestamp | dateTimeToHuman }}
                                                </span>
                                                <span
                                                    v-else-if="
                                                        getInvitationStatus(item) === invitationAndRequestStatusTypes.REJECTED &&
                                                        invitationOrRequestHasTimestamp(item)
                                                    "
                                                    class="mt-1 mb-0">
                                                    Rejected on {{ item.invitation_responses[0].timestamp | dateTimeToHuman }}
                                                </span>
                                            </div>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item-content>
                                        <v-list-item-subtitle>
                                            <div v-for="(role, index) in item.roles" :key="index" class="d-flex flex-column">
                                                <v-tooltip left>
                                                    <template v-slot:activator="{ on }">
                                                        <div v-if="role.role_type !== invitationAndRequestRoleTypes.ORG_ROLE" v-on="on">
                                                            <ComponentWithStyledBorder>
                                                                <span
                                                                    v-if="role.role_type === invitationAndRequestRoleTypes.INSTANCE_ROLE"
                                                                    class="d-flex align-center">
                                                                    <v-icon class="mr-1 ml-1" v-if="role.Name === 'N/A'">help_outline</v-icon>
                                                                    <v-icon v-else-if="role.Name === roleTypes.INSTANCE_VIEWER" class="mr-1 ml-1">
                                                                        visibility
                                                                    </v-icon>
                                                                    <v-icon v-else-if="role.Name === roleTypes.INSTANCE_EDITOR" class="mr-1 ml-1">edit</v-icon>
                                                                    <span v-if="role.Instance">{{ role.Instance }}</span>
                                                                    <span v-else>Target deleted</span>
                                                                </span>
                                                                <span
                                                                    v-else-if="role.role_type === invitationAndRequestRoleTypes.SPACE_ROLE"
                                                                    class="d-flex align-center">
                                                                    <v-icon v-if="role.Name === 'N/A'" class="mr-1 ml-1">help_outline</v-icon>
                                                                    <v-icon v-else class="mr-1 ml-1">security</v-icon>
                                                                    {{ role.Space }}
                                                                </span>
                                                            </ComponentWithStyledBorder>
                                                        </div>
                                                    </template>
                                                    <span v-if="role.Name === 'N/A'">The target object has been deleted</span>
                                                    <span v-else-if="role.method === roleUpdateOptions.OVERWRITE_CURRENT_ROLE">
                                                        This role will overwrite the current user role, even if the current role is more powerful that this one.
                                                    </span>
                                                    <span v-else-if="role.method === roleUpdateOptions.OVERWRITE_CURRENT_ROLE_IF_LOWER">
                                                        This role will overwrite the current role if the current role is less powerful than this one.
                                                    </span>
                                                </v-tooltip>
                                            </div>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                                <v-col cols="1">
                                    <div
                                        v-if="
                                            getInvitationStatus(item) === invitationAndRequestStatusTypes.PENDING ||
                                            getInvitationStatus(item) === invitationAndRequestStatusTypes.CREATED
                                        "
                                        class="d-flex align-center">
                                        <div>
                                            <InvitationActionsDialog
                                                :successFetchAction="
                                                    () => {
                                                        $store.dispatch('spaceStore/fetchSpaceInvitations', $route.params.sid)
                                                    }
                                                "
                                                :invitationInfo="item"
                                                :actionType="actionTypes.RESEND_INVITATION"></InvitationActionsDialog>
                                        </div>
                                        <div>
                                            <copy-to-clipboard
                                                :textToCopy="item.link"
                                                buttonClass="caption font-weight-bold"
                                                buttonColor="secondary"
                                                buttonName="copy to clipboard"
                                                :iconButton="true"
                                                :isSmall="true"></copy-to-clipboard>
                                        </div>
                                        <div>
                                            <InvitationActionsDialog
                                                :successFetchAction="
                                                    () => {
                                                        $store.dispatch('spaceStore/fetchSpaceInvitations', $route.params.sid)
                                                    }
                                                "
                                                :invitationInfo="item"
                                                :actionType="actionTypes.REVOKE_INVITATION"></InvitationActionsDialog>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </div>
                    <invite-btn @goToInvite="$emit('goToInvite', { value: true })" />
                </template>
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning" text class="mt-3">Your search for found no results.</v-alert>
                </template>
            </v-data-iterator>
        </div>
        <div v-else-if="!spaceInvitations.length && !fetchingSpaceInvitations">
            <v-alert text prominent type="info">
                <div class="d-flex flex-column">
                    <span class="font-weight-bold">No invitations found</span>
                    <span>
                        Currently this space has no invitation history. You can
                        <v-btn small dark color="info" outlined @click="$emit('goToInvite', { value: true })">
                            <v-icon small>add</v-icon>
                            Invite
                        </v-btn>
                        new members.
                    </span>
                </div>
            </v-alert>
        </div>
        <div v-else-if="fetchingSpaceInvitations">
            <div
                v-for="item in [
                    { id: 'c1', opacity: 1 },
                    { id: 'c2', opacity: 0.75 },
                    { id: 'c3', opacity: 0.5 }
                ]"
                :key="item.id"
                class="my-6">
                <div :style="{ opacity: item.opacity }">
                    <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { sortArray } from '@/utils'
import { enumsData } from '@/mixins/enums'
import { invitationsAndRequests } from '@/mixins/invitationsAndRequests'
import InviteBtn from '@/components/InviteBtn.vue'
const CopyToClipboard = () => import('@/components/CopyToClipboard')
const InvitationActionsDialog = () => import('@/modules/invitations/components/InvitationsActionsDialog')
const ComponentWithStyledBorder = () => import('@/components/ComponentWithStyledBorder')
export default {
    mixins: [invitationsAndRequests, enumsData],
    components: {
        CopyToClipboard,
        InvitationActionsDialog,
        ComponentWithStyledBorder,
        InviteBtn
    },
    data() {
        return {
            search: '',
            invitationsSwitchData: {
                ACCEPTED: true,
                PENDING: true,
                REJECTED: true,
                REVOKED: true,
                EXPIRED: true,
                BROKEN: true,
                CREATED: true
            },
            invitationEmailSearch: ''
        }
    },
    computed: {
        ...mapState('spaceStore', ['spaceInvitations', 'fetchingSpaceInvitations']),
        availableInvitationTypes: function () {
            const invitationTypes = []
            if (this.spaceInvitations && this.spaceInvitations.length) {
                this.spaceInvitations.forEach(invitation => {
                    const invitationType = this.getInvitationStatus(invitation)
                    if (!invitationTypes.includes(invitationType)) {
                        invitationTypes.push(invitationType)
                    }
                })
                return invitationTypes
            }
            return []
        },
        displayedInvitationTypes: function () {
            const filteredInvitations = Object.values(this.invitationAndRequestStatusTypes).filter(
                value => this.availableInvitationTypes.includes(value) && this.invitationsSwitchData[value]
            )
            return filteredInvitations
        },
        filteredInvitationsList: function () {
            const filterByStatusType = this.spaceInvitations.filter(invitation => this.displayedInvitationTypes.includes(this.getInvitationStatus(invitation)))
            if (!this.invitationEmailSearch) {
                const sortedData = sortArray(filterByStatusType, 'invid', 'descending', false)
                return sortedData
            } else {
                const filteredData = filterByStatusType.filter(invitation => invitation.requestee_email.includes(this.invitationEmailSearch.toLowerCase()))
                const sortedData = sortArray(filteredData, 'invid', 'descending', false)
                return sortedData
            }
        }
    },
    watch: {
        availableInvitationTypes: function (nextVal) {
            if (nextVal.length && nextVal.includes(this.invitationAndRequestStatusTypes.PENDING)) {
                this.invitationsSwitchData = {
                    ACCEPTED: false,
                    PENDING: true,
                    REJECTED: false,
                    REVOKED: false,
                    EXPIRED: false,
                    BROKEN: false,
                    CREATED: false
                }
            }
        }
    }
}
</script>
