import { get } from 'lodash'
export const invitationsAndRequests = {
    data() {
        return {
            roleUpdateOptions: {
                OVERWRITE_CURRENT_ROLE: 0,
                OVERWRITE_CURRENT_ROLE_IF_LOWER: 1
            },
            invitationAndRequestStatusTypes: {
                ACCEPTED: 'ACCEPTED',
                PENDING: 'PENDING',
                REJECTED: 'REJECTED',
                REVOKED: 'REVOKED',
                EXPIRED: 'EXPIRED',
                BROKEN: 'BROKEN',
                CREATED: 'CREATED',
                SENT: 'SENT'
            },
            actionTypes: {
                REVOKE_INVITATION: 'Revoke invitation',
                RESEND_INVITATION: 'Resend invitation',
                REVOKE_REQUEST: 'Revoke request',
                APPROVE_REQUEST: 'Approve request',
                REJECT_REQUEST: 'Reject request'
            },
            requestTypes: {
                OWN_REQUESTS: 'own',
                REQUESTS_TO_APPROVE: 'to approve'
            },
            invitationAndRequestRoleTypes: {
                INSTANCE_ROLE: 'InstanceRoleType',
                SPACE_ROLE: 'SpaceRoleType',
                ORG_ROLE: 'OrgRoleType'
            },
            requestingRole: false,
            roleCodes: {
                ORGANIZATION_MANAGER: 0,
                FACULTY_MEMBER: 10,
                ORGANIZATION_MEMBER: 30,
                AFFILIATED: 20,
                INSTANCE_EDITOR: 0,
                INSTANCE_VIEWER: 10,
                INSTANCE_OBSERVER: 20
            }
        }
    },
    methods: {
        invitationOrRequestHasTimestamp(data) {
            return get(data, 'invitation_responses[0].timestamp') || get(data, 'request_responses[0].timestamp')
        },
        getMailIconColor(status) {
            if (status === this.invitationAndRequestStatusTypes.ACCEPTED) {
                return 'success'
            } else if (status === this.invitationAndRequestStatusTypes.REJECTED) {
                return 'yellow darken-2'
            } else if (status === this.invitationAndRequestStatusTypes.PENDING) {
                return 'primary'
            } else if (status === this.invitationAndRequestStatusTypes.REVOKED) {
                return 'error'
            } else if (status === this.invitationAndRequestStatusTypes.EXPIRED) {
                return 'secondary'
            } else if (status === this.invitationAndRequestStatusTypes.CREATED) {
                return 'purple'
            } else {
                return 'info'
            }
        },
        getInvitationStatus(itemData) {
            if (itemData.invitation_responses.length && itemData.invitation_responses[0].response === this.invitationAndRequestStatusTypes.ACCEPTED) {
                return this.invitationAndRequestStatusTypes.ACCEPTED
            } else if (itemData.invitation_responses.length && itemData.invitation_responses[0].response === this.invitationAndRequestStatusTypes.REJECTED) {
                return this.invitationAndRequestStatusTypes.REJECTED
            } else if (itemData.status_name === this.invitationAndRequestStatusTypes.REVOKED) {
                return this.invitationAndRequestStatusTypes.REVOKED
            } else if (
                itemData.status_name === this.invitationAndRequestStatusTypes.SENT &&
                itemData.valid === 'False' &&
                !itemData.invitation_responses.length
            ) {
                return this.invitationAndRequestStatusTypes.EXPIRED
            } else if (
                itemData.status_name === this.invitationAndRequestStatusTypes.SENT &&
                itemData.valid === 'True' &&
                !itemData.invitation_responses.length
            ) {
                return this.invitationAndRequestStatusTypes.PENDING
            } else if (
                itemData.status_name === this.invitationAndRequestStatusTypes.CREATED &&
                itemData.valid === 'True' &&
                !itemData.invitation_responses.length
            ) {
                return this.invitationAndRequestStatusTypes.CREATED
            } else {
                return this.invitationAndRequestStatusTypes.BROKEN
            }
        },
        submitRequest(roleType, role, generateLinkOnly = false) {
            this.requestingRole = true
            const baseRequestURL = '/requests'
            let apiURL
            const postBody = { generate_link_only: generateLinkOnly }
            if (roleType === this.invitationAndRequestRoleTypes.ORG_ROLE) {
                apiURL = `${baseRequestURL}/request_org_role/${this.$route.params.oid}`
                postBody.org_role = role
            } else if (roleType === this.invitationAndRequestRoleTypes.SPACE_ROLE) {
                apiURL = `${baseRequestURL}/request_space_role/${this.$route.params.sid}`
            } else if (roleType === this.invitationAndRequestRoleTypes.INSTANCE_ROLE) {
                apiURL = `${baseRequestURL}/request_instance_role/${this.$route.params.iid}`
                postBody.instance_role = role
            }
            this.$axios
                .post(apiURL, postBody)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Successfully submitted the request!',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'check_circle'
                    })
                })
                .catch(error => {
                    const errorMessage =
                        error.response && error.response.data && error.response.data.code === 'request_already_exists'
                            ? 'A request for this role has already been submitted.'
                            : 'Failed to submit request, please try again later or contact us via the chat in the bottom right side of the page.'
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: errorMessage,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.requestingRole = false
                })
        }
    }
}
